import React from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { Container } from '@material-ui/core'

import logo from 'assets/img/logo.png'

import partners from 'data/partners'

const PartnersPage = () => {
  const { t } = useTranslation('partnersPage')

  return (
    <div id="certification-page">

      <section id="header">
        <div id="logo-circle">
          <div id="circle">
            <img src={logo} alt="" />
          </div>
        </div>
      </section>

      <section className="rounded-page">
        <Container>
          <section>
            <h1 className="title-page">{t('title')}</h1>
            <div className="content">
              <Trans
                t={t}
                i18nKey='text'
                components={{ 1: <div />, 2: <b /> }}
              />
            </div>
          </section>

          <section id="partners">
            {partners.map(({ id, image, link }, index) => {
              if (id === 3) {
                return <h1>{t('technicalPartner')}</h1>
              } else {
                return (
              <article className="card" key={id}>
                <div><img src={image} alt="" /></div>
                <div className='link'><a href={link} target="_blank" rel="noreferrer">{t('partnersLink')}</a></div>
              </article>
                )
              }
            })}
          </section>

        </Container>
      </section>
    </div>
  )
}

export default PartnersPage
