import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'

import { useTranslation } from 'react-i18next'
import './style.scss'
import useNavigation from 'hooks/useNavigation'
import useLotData from 'hooks/useLotData'

const ITEM_HEIGHT = 48
export default function LongMenu () {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const { sectionActive, changeSection, resetURL } = useNavigation()
  const { lotNotFound, setLotNotFound } = useLotData()

  console.log('sectionActive', sectionActive)
  const { t } = useTranslation('menu')
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickSection = (n) => {
    handleClose()
    // Se è attivo il flag lot not found
    // rimuovo /lot/LOT_ID dall'url e ripristino lo stato
    if (lotNotFound) {
      resetURL()
      setLotNotFound(false)
    }
    changeSection(n)
  }

  const options = [
    t('specifications'),
    t('company'),
    t('partners'),
    t('traceability')
  ]

  return (
    <div className="hamburgerMenuPosition">
      <IconButton
        className="iconColor"
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            borderRadius: '20%',
            color: '#3c4858',
            fontFamily: "'Open Sans', sans-serif !important"
          }
        }}
      >
        {options.map((option, index) => (
          <MenuItem className={sectionActive !== index + 1 ? null : 'menuItemColor'} key={option} onClick={() => { handleClickSection(index + 1) }}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
