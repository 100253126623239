/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
// import { Scene } from 'react-scrollmagic'
// import { Timeline, Tween } from 'react-gsap'
import Box from '@mui/material/Box'

import { useTranslation } from 'react-i18next'

import {
  Container
} from '@material-ui/core'

import DettaglioFaseModal from 'components/DettaglioFaseModal/DettaglioFaseModal'
// import DettaglioFaseSlider from 'components/DettaglioFaseSlider/DettaglioFaseSlider'
import CustomModal from 'components/CustomModal/CustomModal'
import TraceabilityTable from 'components/TraceabilityTable/TraceabilityTable'
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
const TraceabilityCard = ({ card }) => {
  const { t } = useTranslation('traceabilityCard')
  const [selectedDettaglio, setSelectedDettaglio] = useState({})
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)

  const [isOpenMoreModal, setIsOpenMoreModal] = useState(false)

  const [cardHeight, setCardHeight] = useState(0)

  const handleClick = (e) => {
    e.preventDefault()
    setIsOpenMoreModal(true)
  }

  useEffect(() => {
    const height = document.getElementsByClassName('traceability-card')[0]?.clientHeight
    setCardHeight(height)
  }, [])

  const body = (
    <article className={'traceability-card'}>
      <section className='image' style={{ backgroundImage: 'url(' + card.header + ')' }}></section>
      <section className="title">
        <Container>
          <h1>{card.title}</h1>
        </Container>
      </section>
      <section className='description'>
        <Container>
          {card.shortText
            ? <> {card.shortText} <a href="!#" onClick={handleClick}>{t('showMore')}</a> </>
            : card.text
          }
        </Container>
      </section>
      <section className='button'>
        <Container>
          {card.dettaglio.circleCover &&
          card.id < 6
            ? <>
            {/* <div className='logo-circle'>
                <img className='inner-image' onClick={handleClick} src={card.dettaglio.circleCover} alt='' />
            </div> */}
            <div className="centerContent">
                <button className='button-down' onClick={handleClick}>{t('buttonTraceability')}</button>
            </div>
              </>
            : <>
            {/* <div className='logo-circle'>
                <img className='inner-image-last-card' onClick={handleClick} src={card.dettaglio.circleCover} alt='' />
            </div> */}
            <div className="centerContent">
              <button className='button-down' onClick={handleClick}>{t('buttonTraceability')}</button>
            </div>
              </>
          }
        </Container>
      </section>
    </article>
  )

  return (
    <>
      {isOpenMoreModal &&
        <CustomModal
          isOpen={isOpenMoreModal}
          setIsOpen={setIsOpenMoreModal}
          className='more-text-modal'
          closeButton
        >
          <section id="header">
            <div className="image" style={{ backgroundImage: 'url(' + card.header + ')', backgroundSize: 'cover' }}></div>
          </section>
          <section id="body">
            <Container id="text">
            <Box sx={{
              width: 'auto',
              height: 'auto',
              padding: ' 5px',
              backgroundColor: '#87837826',
              '&:hover': {
                backgroundColor: '#87837826',
                opacity: [0.9, 0.8, 0.7]
              }
            }}>
              <p>{t('callout')}</p>
              </Box>
              <p id='callout'>{card.text}</p>
            </Container>
            {/* qui dentro metto la tabella */}
            <TraceabilityTable data={card.dati} />
          </section>
        </CustomModal>
      }
      {isOpenDetailModal &&
        <DettaglioFaseModal
          item={selectedDettaglio}
          isOpen={isOpenDetailModal}
          setIsOpen={setIsOpenDetailModal}
        />
      }
      {body}
      {/* {card.id < 6
        ? (
          <Scene
            pin
            duration={cardHeight}
            pinSettings={{ pushFollowers: false }}
            triggerHook='onLeave'
          >
            <Timeline target={body}>
              <Tween duration={1} />
              <Tween
                duration={1}
                from={{ opacity: 1 }}
                to={{ opacity: 0 }}
              />
              <Tween duration={1} />
            </Timeline>
          </Scene>
          )
        : body
      } */}
    </>
  )
}

export default TraceabilityCard
