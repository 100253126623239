/* eslint-disable no-unused-vars */
import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import CustomModal from 'components/CustomModal/CustomModal'

import './style.scss'

const DettaglioFaseModal = (props) => {
  const { item, isOpen, setIsOpen } = props

  const { t } = useTranslation('dettaglioFaseModal')

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="dettaglio-fase-modal"
      closeButton
    >
      <section id="header">
        <div className="image" style={{ backgroundImage: 'url(' + item.header + ')' }}></div>
      </section>

      <section className="rounded-page">
        <Container>
          <h1>{item.title}</h1>
          <p>{item.text}</p>

          <article>
            <Grid container className="detail" alignItems='center' spacing={2}>
              <Grid item xs={12}>{item.features.title}</Grid>
              <Grid item xs={12}>{item.features.text}</Grid>
            </Grid>
          </article>

        </Container>
      </section>
    </CustomModal>
  )
}

export default DettaglioFaseModal
