import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import logo from 'assets/img/logo.png'
import image from 'assets/img/company-page/image.png'

// import contactAddressIcon from 'assets/img/company-page/contact/address.svg'
// import contactEshopIcon from 'assets/img/company-page/contact/eshop.svg'
// import contactFaxIcon from 'assets/img/company-page/contact/fax.svg'
import contactMailIcon from 'assets/img/company-page/contact/mail.svg'
import contactPivaIcon from 'assets/img/company-page/contact/piva.svg'
import contactTelephoneIcon from 'assets/img/company-page/contact/telephone.svg'
// import contactWebsiteIcon from 'assets/img/company-page/contact/website.svg'

const CompanyPage = () => {
  const { t } = useTranslation('companyPage')

  return (
    <div id="company-page">
      <section id="header"></section>

      <section className="rounded-page">
        <Container>

          <article id="azienda">
            <h1 className="title-page">{t('company.title')}</h1>
            <p>{t('company.text1')}</p>
            <div className='center-text'>
              <img src={image} alt='' style={{ width: '100%' }} />
            </div>
            <p>{t('company.text2')}</p>
          </article>

          <article id="numeri">
            <div id="logo-circle">
              <img src={logo} alt="" />
              <span className='circle' />
            </div>
            <ul>
              <li>
                <h1>10</h1>
                <h3>{t('numbers.1')}</h3>
              </li>
              <li>
                <h1>40%</h1>
                <h3>{t('numbers.2')}</h3>
              </li>
              <li>
                <h1>70%</h1>
                <h3>{t('numbers.3')}</h3>
              </li>
            </ul>
          </article>

          <article id="contact">
            <h1>Comoé Equitable</h1>
            <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6180608.5418196805!2d-6.973665126390718!3d4.70647531307928!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNcKwMTInMTkuNyJOIDPCsDQ0JzA4LjciVw!5e0!3m2!1sit!2sit!4v1632991812059!5m2!1sit!2sit" allowFullScreen={true} loading="lazy"></iframe>
            <Grid container id="detail" alignItems="center">
              {/* <Grid item xs={3}><img src={contactAddressIcon} alt="" /></Grid>
              <Grid item xs={9}>Via C. Masetti, 6 - 40069<br />Zola Predosa (BO) - Italia</Grid> */}
              <Grid item xs={3}><img src={contactPivaIcon} alt="" /></Grid>
              <Grid item xs={9}>{t('contact.piva')} 03561781208</Grid>
              <Grid item xs={3}><img src={contactTelephoneIcon} alt="" /></Grid>
              <Grid item xs={9}>+225 0170509494</Grid>
              {/* <Grid item xs={3}><img src={contactFaxIcon} alt="" /></Grid>
              <Grid item xs={9}>+39 051 0190602</Grid> */}
              <Grid item xs={3}><img src={contactMailIcon} alt="" /></Grid>
              <Grid item xs={9}><a href="mailto: join@chocoplus.ci">join@chocoplus.ci</a></Grid>
              {/* <Grid item xs={3}><img src={contactEshopIcon} alt="" /></Grid>
              <Grid item xs={9}><a href="!#" target="_blank" rel="noreferrer">shop.felsineoveg.com</a></Grid>
              <Grid item xs={3}><img src={contactWebsiteIcon} alt="" /></Grid>
              <Grid item xs={9}><a href="!#" target="_blank" rel="noreferrer">www.felsineoveg.com/</a></Grid> */}
            </Grid>
          </article>

        </Container>
      </section>
    </div>
  )
}

export default CompanyPage
