import React from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { Container, Grid } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'
import Image from 'material-ui-image'

import logo from 'assets/img/logo.png'
import productImage from 'assets/img/product-page/product-image.png'

import image1 from 'assets/img/product-page/image1.png'
import image2 from 'assets/img/product-page/image2.png'
import image3 from 'assets/img/product-page/image3.png'

import getProduct from 'data/product'

const ProductPage = () => {
  const product = getProduct()
  const { t } = useTranslation('productPage')

  return (
    <div id="product-page">
      <section id="header">
        <Container>
          <div id="logo">
            <img src={logo} alt="" />
            <span className='circle' />
          </div>
          <h1 id="product-title">{t('title')}</h1>
          <div id="product-image">
            <img src={productImage} alt="" />
            <span id="circle" />
          </div>
          <p id="description">{t('description')}</p>
        </Container>
      </section>

      <section className="rounded-page">
          <article id="ingredienti">
            <Container>
              <h3>INGREDIENTI</h3>
            </Container>
            <Grid container>
              <Grid item xs={12} className='image'>
                <img src={image1} alt='' />
              </Grid>
              <Grid item xs={12}>
                <Container>
                  <div className='content'>
                    <p>
                      <Trans
                        t={t}
                        i18nKey='ingredienti'
                        components={{ 1: <b /> }}
                      />
                    </p>
                  </div>
                </Container>
              </Grid>
            </Grid>
          </article>

          <article id="aroma">
            <Container>
              <h3>AROMA</h3>
              <Grid container>
                <Grid item xs={8}>
                  <div className='content'>
                    <p>{t('aroma')}</p>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <img src={image2} alt='' />
                </Grid>
              </Grid>
            </Container>
          </article>

          <article id="esperienza">
            <Container>
              <h3>ESPERIENZA</h3>
              <Grid container>
                <Grid item xs={4}>
                  <img src={image3} alt='' />
                </Grid>
                <Grid item xs={8}>
                  <div className='content'>
                    <p>{t('esperienza')}</p>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </article>

          <article id="valori-nutrizionali">
            <Container>
              <p>{t('valoriNutrizionali.title')}</p>
              <table>
                <tbody>
                  <tr>
                    <td>{t('valoriNutrizionali.data.calory')}</td>
                    <td>169,6 g</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{t('valoriNutrizionali.data.calory')}</div>
                      <div>{t('valoriNutrizionali.data.fiber')}</div>
                      <div>{t('valoriNutrizionali.data.shugar')}</div>
                    </td>
                    <td>
                      <div>58 g</div>
                      <div>23,1 g</div>
                      <div>1,29 g</div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('valoriNutrizionali.data.protein')}</td>
                    <td>14 g</td>
                  </tr>
                  <tr>
                    <td>
                      <div>{t('valoriNutrizionali.data.calory')}</div>
                      <div>{t('valoriNutrizionali.data.acid1')}</div>
                      <div>{t('valoriNutrizionali.data.acid2')}</div>
                      <div>{t('valoriNutrizionali.data.acid3')}</div>
                    </td>
                    <td>
                      <div>9,8 g</div>
                      <div>5,6 g</div>
                      <div>0,28 g</div>
                      <div>3,22 g</div>
                    </td>
                  </tr>
                  <tr>
                    <td>{t('valoriNutrizionali.data.cholesterol')}</td>
                    <td>0 mg</td>
                  </tr>
                  <tr>
                    <td>{t('valoriNutrizionali.data.potassium')}</td>
                    <td>1066,8 mg</td>
                  </tr>
                  <tr>
                    <td>{t('valoriNutrizionali.data.caffeine')}</td>
                    <td>161 mg</td>
                  </tr>
                  <tr>
                    <td>{t('valoriNutrizionali.data.magnesium')}</td>
                    <td>349,30 mg</td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </article>

          <article id="choco-fair">
            <Container>
              <img src={logo} alt='' />
              {/* <h3>{t('choco.title')}</h3> */}
            </Container>
            <div id="content">
              <Container>
                <Carousel
                  autoPlay={false}
                  animation='slide'
                  indicatorIconButtonProps={{
                    style: { color: '#000' }
                  }}
                  activeIndicatorIconButtonProps={{
                    style: { color: '#FFF' }
                  }}
                  navButtonsAlwaysInvisible={true}
                >
                {product.photo.map(([id, image]) => (
                  <Image
                    key={id}
                    src={image}
                    color='common.transparent'
                    disableSpinner
                  />
                ))}
                </Carousel>
                {/* <p>{t('choco.content')}</p> */}
              </Container>
            </div>
          </article>

      </section>
    </div>
  )
}

export default ProductPage
