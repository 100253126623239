import { useState, useEffect, useCallback } from 'react'

const getOrientation = () => {
  if (window.orientation) {
    return Math.abs(window.orientation) === 90 ? 'landscape' : 'portrait'
  }

  if (window.screen.orientation) {
    return window.screen.orientation.type.split('-')[0]
  }

  return window.screen.width > window.screen.height ? 'landscape' : 'portrait'
}

const useScreenOrientation = () => {
  const [orientation, setOrientation] = useState(getOrientation())
  const [isLandscape, setIsLandsape] = useState()
  const [isPortrait, setIsPortrait] = useState()
  const [_onOrientationChange, _setOnOrientationChange] = useState()

  const handleOrientationChange = useCallback(() => {
    const _orientation = getOrientation()

    setOrientation(_orientation)

    if (_onOrientationChange) {
      _onOrientationChange(_orientation)
    }
  }, [_onOrientationChange])

  useEffect(() => {
    if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
      window.screen.orientation.addEventListener('change', handleOrientationChange)
    } else if ('onorientationchange' in window) {
      window.addEventListener('orientationchange', handleOrientationChange)
    } else {
      console.warn('No orientationchange events')
    }

    return () => {
      if ((window.screen.orientation) && ('onchange' in window.screen.orientation)) {
        window.screen.orientation.removeEventListener('change', handleOrientationChange)
      } else if ('onorientationchange' in window) {
        window.removeEventListener('orientationchange', handleOrientationChange)
      }
    }
  }, [handleOrientationChange])

  useEffect(() => {
    setIsLandsape(orientation === 'landscape')
    setIsPortrait(orientation === 'portrait')
  }, [orientation])

  const onOrientationChange = (callback) => {
    if (typeof callback !== 'function') {
      throw new Error('Error: Invalid prop. onOrientationChange prop must be a callback function')
    }
    _setOnOrientationChange(callback)
  }

  return { orientation, isLandscape, isPortrait, onOrientationChange }
}

export default useScreenOrientation
