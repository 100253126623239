import { useTranslation } from 'react-i18next'
import React from 'react'

// import { useTranslation } from 'react-i18next'

// import { Link } from '@material-ui/core'
import DialogDiscover from 'components/DialogDiscover/DialogDiscover'
import header0 from 'assets/img/traceability-page/header-fasi/0.png'
import header1 from 'assets/img/traceability-page/header-fasi/1.png'
import header2 from 'assets/img/traceability-page/header-fasi/2.png'
import header3 from 'assets/img/traceability-page/header-fasi/3.png'
import header4 from 'assets/img/traceability-page/header-fasi/4.png'
import header5 from 'assets/img/traceability-page/header-fasi/5.png'
import header6 from 'assets/img/traceability-page/header-fasi/6.png'

import dettaglioCover0 from 'assets/img/traceability-page/cover-fasi/0.png'
import dettaglioCover1 from 'assets/img/traceability-page/cover-fasi/1.png'
import dettaglioCover2 from 'assets/img/traceability-page/cover-fasi/2.png'
import dettaglioCover3 from 'assets/img/traceability-page/cover-fasi/3.png'
import dettaglioCover4 from 'assets/img/traceability-page/cover-fasi/4.png'
import dettaglioCover5 from 'assets/img/traceability-page/cover-fasi/5.png'
import dettaglioCover6 from 'assets/img/traceability-page/cover-fasi/6.png'

const DISCOVER_LINK_FEVEAGRIMAGNI = 'https://discover.trusty.id/gtin/c47197ba-c9bb-4e65-aed3-23cf7b38de4b/lot/'
const DISCOVER_LINK_FEVE = 'https://discover.trusty.id/gtin/a283f5ed-945c-4a63-8a44-92e552699d7f/lot/'
const DISCOVER_LINK_CHOCOPLUS = 'https://discover.trusty.id/gtin/48f7d940-b9d8-4638-93a0-6b15f670c6a7-p00000013/lot/'
const useLot = () => {
  const { t } = useTranslation('traceabilityCard')

  return {
    traceability: [
      {
        id: 0,
        title: t('cards.0.title'),
        header: header0,
        dettaglio: {
          circleCover: dettaglioCover0
        },
        dati: {
          tabs: [
            {
              key: '0x0',
              className: 'tabsStep1',
              title: <b>{t('cards.0.table.0.header.title')}</b>,
              rows: [
                [ // header
                  <b key='b0r0c0'>{t('cards.0.table.0.header.4')}</b>,
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r0c0' target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`} color="inherit">AM2509/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM2509/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`}
                  ></DialogDiscover>,
                  '82 Kg'
                ],
                [ // header
                  t('cards.0.table.0.header.0'),
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r1c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210082021')}`} color="inherit" variant="body2">MAKRO210082021</Link>,
                  <DialogDiscover
                  key='r1c0'
                  lotNumber={'MAKRO210082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210082021')}`}
                  ></DialogDiscover>,
                  '8 Kg'
                ],
                [
                  t('cards.0.table.0.header.2'),
                  t('cards.0.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '10/08/2021'
                ],
                [ // header
                  t('cards.0.table.0.header.0'),
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r2c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO224082021')}`} color="inherit" variant="body2">MAKRO224082021</Link>,
                  <DialogDiscover
                  key='r2c0'
                  lotNumber={'MAKRO224082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO224082021')}`}
                  ></DialogDiscover>,
                  '21 Kg'
                ],
                [
                  t('cards.0.table.0.header.2'),
                  t('cards.0.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '24/08/2021'
                ],
                [ // header
                  t('cards.0.table.0.header.0'),
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r3c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO125082021')}`} color="inherit" variant="body2">MAKRO125082021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'MAKRO125082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO125082021')}`}
                  ></DialogDiscover>,
                  '10 Kg'
                ],
                [
                  t('cards.0.table.0.header.2'),
                  t('cards.0.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 1',
                  '26/08/2021'
                ],
                [ // header
                  t('cards.0.table.0.header.0'),
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r4c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB16082021')}`} color="inherit">AKKOB16082021</Link>,
                  <DialogDiscover
                  key='r4c0'
                  lotNumber={'AKKOB16082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB16082021')}`}
                  ></DialogDiscover>,
                  '17 Kg'
                ],
                [
                  t('cards.0.table.0.header.2'),
                  t('cards.0.table.0.header.3')
                ],
                [
                  'AKRESSI KONGODJAN B',
                  '16/08/2021'
                ],
                [ // header
                  t('cards.0.table.0.header.0'),
                  t('cards.0.table.0.header.1')
                ],
                [
                  // <Link key='r5c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB04092021')}`} color="inherit">AKKOB04092021</Link>,
                  <DialogDiscover
                  key='r5c0'
                  lotNumber={'AKKOB04092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB04092021')}`}
                  ></DialogDiscover>,
                  '26 Kg'
                ],
                [
                  t('cards.0.table.0.header.2'),
                  t('cards.0.table.0.header.3')
                ],
                [
                  'AKRESSI KONGODJAN B',
                  '06/09/2021'
                ]]
            },
            {
              key: '0x1',
              className: 'tabsStep1',
              rows:
              [
                [ // header
                  <b key='b0r0c0'>{t('cards.0.table.1.header.4')}</b>,
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r0c0' target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`} color="inherit">AM0610/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM0610/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`}
                  ></DialogDiscover>,
                  '105 Kg'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r1c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P3CIT107092021')}`} color="inherit" variant="body2">P3CIT107092021</Link>,
                  <DialogDiscover
                  key='r1c0'
                  lotNumber={'P3CIT107092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P3CIT107092021')}`}
                  ></DialogDiscover>,
                  '6 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'PISTE3 CITE 1',
                  '08/09/2021'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r2c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SELIE115092021')}`} color="inherit" variant="body2">SELIE115092021</Link>,
                  <DialogDiscover
                  key='r2c0'
                  lotNumber={'SELIE115092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SELIE115092021')}`}
                  ></DialogDiscover>,
                  '12 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'SELLIE 1',
                  '16/09/2021'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r3c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210092021')}`} color="inherit" variant="body2">MAKRO210092021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'MAKRO210092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210092021')}`}
                  ></DialogDiscover>,
                  '44 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '10/09/2021'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r4c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO110092021')}`} color="inherit">MAKRO110092021</Link>,
                  <DialogDiscover
                  key='r4c0'
                  lotNumber={'MAKRO110092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO110092021')}`}
                  ></DialogDiscover>,
                  '16 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 1',
                  '10/09/2021'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r5c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SHKG-G06092021')}`} color="inherit">SHKG-G06092021</Link>,
                  <DialogDiscover
                  key='r5c0'
                  lotNumber={'SHKG-G06092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SHKG-G06092021')}`}
                  ></DialogDiscover>,
                  '9 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'SANHOUKRO G',
                  '06/09/2021'
                ],
                [ // header
                  t('cards.0.table.1.header.0'),
                  t('cards.0.table.1.header.1')
                ],
                [
                  // <Link key='r6c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P1GAU15092021')}`} color="inherit">P1GAU15092021</Link>,
                  <DialogDiscover
                  key='r6c0'
                  lotNumber={'P1GAU15092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P1GAU15092021')}`}
                  ></DialogDiscover>,
                  '18 Kg'
                ],
                [
                  t('cards.0.table.1.header.2'),
                  t('cards.0.table.1.header.3')
                ],
                [
                  'PISTE 1 GAUCHE',
                  '16/09/2021'
                ]
              ]
            }
          ]
        }
      },
      {
        id: 1,
        title: t('cards.1.title'),
        header: header1,
        dettaglio: {
          circleCover: dettaglioCover1
        },
        dati: {
          tabs: [
            {
              key: '1x0',
              title: <b>{t('cards.1.table.0.header.title')}</b>,
              className: 'tabsStep1',
              rows: [
                [ // header
                  <b key='b0r0c0'>{t('cards.1.table.0.header.4')}</b>,
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r0c0' target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`} color="inherit">AM2509/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM2509/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`}
                  ></DialogDiscover>,
                  '82 Kg'
                ],
                [ // header
                  t('cards.1.table.0.header.0'),
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r1c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210082021')}`} color="inherit" variant="body2">MAKRO210082021</Link>,
                  <DialogDiscover
                  key='r1c0'
                  lotNumber={'MAKRO210082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210082021')}`}
                  ></DialogDiscover>,
                  '8 Kg'
                ],
                [
                  t('cards.1.table.0.header.2'),
                  t('cards.1.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '10/08/2021 - 23/08/2021'
                ],
                [ // header
                  t('cards.1.table.0.header.0'),
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r2c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO224082021')}`} color="inherit" variant="body2">MAKRO224082021</Link>,
                  <DialogDiscover
                  key='r2c0'
                  lotNumber={'MAKRO224082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO224082021')}`}
                  ></DialogDiscover>,
                  '21 Kg'
                ],
                [
                  t('cards.1.table.0.header.2'),
                  t('cards.1.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '24/08/2021 - 06/09/2021'
                ],
                [ // header
                  t('cards.1.table.0.header.0'),
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r3c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO125082021')}`} color="inherit" variant="body2">MAKRO125082021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'MAKRO125082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO125082021')}`}
                  ></DialogDiscover>,
                  '10 Kg'
                ],
                [
                  t('cards.1.table.0.header.2'),
                  t('cards.1.table.0.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 1',
                  '27/08/2021 - 08/09/2021'
                ],
                [ // header
                  t('cards.1.table.0.header.0'),
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r4c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB16082021')}`} color="inherit">AKKOB16082021</Link>,
                  <DialogDiscover
                  key='r4c0'
                  lotNumber={'AKKOB16082021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB16082021')}`}
                  ></DialogDiscover>,
                  '17 Kg'
                ],
                [
                  t('cards.1.table.0.header.2'),
                  t('cards.1.table.0.header.3')
                ],
                [
                  'AKRESSI KONGODJAN B',
                  '16/08/2021 - 28/08/2021'
                ],
                [ // header
                  t('cards.1.table.0.header.0'),
                  t('cards.1.table.0.header.1')
                ],
                [
                  // <Link key='r5c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB04092021')}`} color="inherit">AKKOB04092021</Link>,
                  <DialogDiscover
                  key='r5c0'
                  lotNumber={'AKKOB04092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('AKKOB04092021')}`}
                  ></DialogDiscover>,
                  '26 Kg'
                ],
                [
                  t('cards.1.table.0.header.2'),
                  t('cards.1.table.0.header.3')
                ],
                [
                  'AKRESSI KONGODJAN B',
                  '06/09/2021 - 17/09/2021'
                ]]
            },
            {
              key: '1x1',
              className: 'tabsStep1',
              rows:
              [
                [ // header
                  <b key='b0r0c0'>{t('cards.1.table.1.header.4')}</b>,
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r0c0' target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`} color="inherit">AM0610/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM0610/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`}
                  ></DialogDiscover>,
                  '105 Kg'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r1c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P3CIT107092021')}`} color="inherit" variant="body2">P3CIT107092021</Link>,
                  <DialogDiscover
                  key='r1c0'
                  lotNumber={'P3CIT107092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P3CIT107092021')}`}
                  ></DialogDiscover>,
                  '6 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'PISTE3 CITE 1',
                  '09/09/2021 - 21/09/2021'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r2c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SELIE115092021')}`} color="inherit" variant="body2">SELIE115092021</Link>,
                  <DialogDiscover
                  key='r2c0'
                  lotNumber={'SELIE115092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SELIE115092021')}`}
                  ></DialogDiscover>,
                  '12 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'SELLIE 1',
                  '16/09/2021 - 01/10/2021'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r3c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210092021')}`} color="inherit" variant="body2">MAKRO210092021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'MAKRO210092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO210092021')}`}
                  ></DialogDiscover>,
                  '44 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 2',
                  '11/09/2021 - 23/09/2021'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r4c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO110092021')}`} color="inherit">MAKRO110092021</Link>,
                  <DialogDiscover
                  key='r4c0'
                  lotNumber={'MAKRO110092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('MAKRO110092021')}`}
                  ></DialogDiscover>,
                  '16 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'PISTE 3 MAGNIKRO 1',
                  '10/09/2021 - 22/09/2021'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r5c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SHKG-G06092021')}`} color="inherit">SHKG-G06092021</Link>,
                  <DialogDiscover
                  key='r5c0'
                  lotNumber={'SHKG-G06092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('SHKG-G06092021')}`}
                  ></DialogDiscover>,
                  '9 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'SANHOUKRO G',
                  '06/09/2021 - 20/09/2021'
                ],
                [ // header
                  t('cards.1.table.1.header.0'),
                  t('cards.1.table.1.header.1')
                ],
                [
                  // <Link key='r6c0' target="_blank" href={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P1GAU15092021')}`} color="inherit">P1GAU15092021</Link>,
                  <DialogDiscover
                  key='r6c0'
                  lotNumber={'P1GAU15092021'}
                  link={`${DISCOVER_LINK_FEVE}${encodeURIComponent('P1GAU15092021')}`}
                  ></DialogDiscover>,
                  '18 Kg'
                ],
                [
                  t('cards.1.table.1.header.2'),
                  t('cards.1.table.1.header.3')
                ],
                [
                  'PISTE 1 GAUCHE',
                  '17/09/2021 - 30/09/2021'
                ]
              ]
            }
          ]
        }
      },
      {
        id: 2,
        title: t('cards.2.title'),
        header: header2,
        dettaglio: {
          circleCover: dettaglioCover2
        },
        dati: {
          tabs: [
            {
              title: <b>{t('cards.2.table.0.header.title')}</b>,
              key: '2x0',
              rows: [
                [
                  t('cards.2.table.0.header.0'),
                  t('cards.2.table.0.header.1')
                ],
                [
                  // <Link key={'r1c0'} target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`} color="inherit">AM2509/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM2509/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`}
                  ></DialogDiscover>,
                  '82 Kg'
                ],
                [
                  t('cards.2.table.0.header.0'),
                  t('cards.2.table.0.header.1')
                ],
                [
                  // <Link key={'r1c0'} target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`} color="inherit">AM0610/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM0610/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`}
                  ></DialogDiscover>,
                  '105 Kg'
                ],
                [
                  t('cards.2.table.0.header.2'),
                  t('cards.2.table.0.header.3')
                ],
                [
                  'CENTRE EXPLOITATION - PISTE 3',
                  'LABORATOIRE CHOCOPLUS'
                ],
                [
                  'Data Partenza',
                  'Data Arrivo'
                ],
                [
                  '25/09/2021',
                  '06/10/2021'
                ]]
            }
          ]
        }
      },
      {
        id: 3,
        title: t('cards.3.title'),
        header: header3,
        dettaglio: {
          circleCover: dettaglioCover3
        },
        dati: {
          tabs: [
            {
              // className: 'table5',
              title: <b>{t('cards.3.table.0.header.title')}</b>,
              key: '3x0',
              rows: [
                [
                  t('cards.3.table.0.header.1'),
                  t('cards.3.table.0.header.2')
                ],
                [
                  '07/10/2021',
                  '08/10/2021'
                ],
                [
                  t('cards.3.table.0.header.0')
                ],
                [
                  'LABORATOIRE CHOCOPLUS'
                ]]
            },
            {
              title: <b>{t('cards.3.table.1.header.title')}</b>,
              key: '3x1',
              rows: [
                [
                  t('cards.3.table.1.header.0'),
                  t('cards.3.table.1.header.1')
                ],
                [
                  // <Link key={'r1c0'} target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`} color="inherit">AM2509/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM2509/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM2509/21')}`}
                  ></DialogDiscover>,
                  '82 Kg'
                ],
                [
                  t('cards.3.table.1.header.2')
                ],
                [
                  'Fave di Cacao'
                ],
                [
                  t('cards.3.table.1.header.0'),
                  t('cards.3.table.1.header.1')
                ],
                [
                  // <Link key={'r1c0'} target="_blank" href={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`} color="inherit">AM0610/21</Link>,
                  <DialogDiscover
                  key='r0c0'
                  lotNumber={'AM0610/21'}
                  link={`${DISCOVER_LINK_FEVEAGRIMAGNI}${encodeURIComponent('AM0610/21')}`}
                  ></DialogDiscover>,
                  '105 Kg'
                ],
                [
                  t('cards.3.table.1.header.2')
                ],
                [
                  'Fave di Cacao'
                ]]
            },
            {
              title: <b>{t('cards.3.table.2.header.title')}</b>,
              key: '3x2',
              rows: [
                [
                  t('cards.3.table.2.header.0'),
                  t('cards.3.table.2.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '182 Kg'
                ],
                [
                  t('cards.3.table.2.header.2')
                ],
                [
                  'Fave di Cacao'
                ]]
            }]
        }
      },
      {
        id: 4,
        title: t('cards.4.title'),
        header: header4,
        dettaglio: {
          circleCover: dettaglioCover4
        },
        dati: {
          tabs: [
            {
              title: <b>{t('cards.4.table.0.header.title')}</b>,
              key: '3x0',
              rows: [
                [
                  t('cards.4.table.0.header.1'),
                  t('cards.4.table.0.header.2')
                ],
                [
                  '07/10/2021',
                  '10/10/2021'
                ],
                [
                  t('cards.4.table.0.header.0')
                ],
                [
                  'LABORATOIRE CHOCOPLUS'
                ]]
            },
            {
              title: <b>{t('cards.4.table.1.header.title')}</b>,
              key: '3x1',
              rows: [
                [
                  t('cards.4.table.1.header.0'),
                  t('cards.4.table.1.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '182 Kg'
                ],
                [
                  t('cards.4.table.1.header.2')
                ],
                [
                  'Fave di Cacao'
                ]]
            },
            {
              title: <b>{t('cards.4.table.2.header.title')}</b>,
              key: '3x2',
              rows: [
                [
                  t('cards.4.table.2.header.0'),
                  t('cards.4.table.2.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '172,21 Kg'
                ],
                [
                  t('cards.4.table.2.header.2')
                ],
                [
                  'Cioccolato'
                ]]
            }
          ]
        }
      },
      {
        id: 5,
        title: t('cards.5.title'),
        header: header5,
        dettaglio: {
          circleCover: dettaglioCover5
        },
        dati: {
          tabs: [
            {
              title: <b>{t('cards.5.table.0.header.title')}</b>,
              key: '3x0',
              rows: [
                [
                  t('cards.5.table.0.header.1'),
                  t('cards.5.table.0.header.2')
                ],
                [
                  '08/10/2021',
                  '16/10/2021'
                ],
                [
                  t('cards.5.table.0.header.0')
                ],
                [
                  'LABORATOIRE CHOCOPLUS'
                ]]
            },
            {
              title: <b>{t('cards.5.table.1.header.title')}</b>,
              key: '3x1',
              rows: [
                [
                  t('cards.5.table.1.header.0'),
                  t('cards.5.table.1.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '172,21 Kg'
                ],
                [
                  t('cards.5.table.1.header.2')
                ],
                [
                  'Cioccolato'
                ]]
            },
            {
              title: <b>{t('cards.5.table.2.header.title')}</b>,
              key: '3x2',
              rows: [
                [
                  t('cards.5.table.2.header.0'),
                  t('cards.5.table.2.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '3288 Tavolette'
                ],
                [
                  t('cards.5.table.2.header.2')
                ],
                [
                  'Cioccolato 70%'
                ]]
            }
          ]
        }
      },
      {
        id: 6,
        title: t('cards.6.title'),
        header: header6,
        dettaglio: {
          circleCover: dettaglioCover6
        },
        dati: {
          tabs: [
            {
              title: <b>{t('cards.6.table.0.header.title')}</b>,
              key: '3x0',
              rows: [
                [
                  t('cards.6.table.0.header.1'),
                  t('cards.6.table.0.header.2')
                ],
                [
                  '20/10/2021',
                  '04/11/2021'
                ],
                [
                  t('cards.6.table.0.header.0'),
                  t('cards.6.table.0.header.3')
                ],
                [
                  'LABORATOIRE CHOCOPLUS',
                  'DOMORI SPA'
                ]]
            },
            {
              title: <b>{t('cards.6.table.1.header.title')}</b>,
              key: '3x2',
              rows: [
                [
                  t('cards.6.table.1.header.0'),
                  t('cards.6.table.1.header.1')
                ],
                [
                  // <Link key={'r3c0'} target="_blank" href={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`} color="inherit">012-2021</Link>,
                  <DialogDiscover
                  key='r3c0'
                  lotNumber={'012-2021'}
                  link={`${DISCOVER_LINK_CHOCOPLUS}${encodeURIComponent('012-2021')}`}
                  ></DialogDiscover>,
                  '3288 Tavolette'
                ],
                [
                  t('cards.6.table.1.header.2')
                ],
                [
                  'Cioccolato 70%'
                ]]
            }
          ]
        }
      }
    ]
  }
}

export default useLot
