import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

import italianCommon from './translations/it/common.json'
// import englishCommon from './translations/en/common.json'

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'localStorage', 'cookie', 'querystring', 'htmlTag', 'path', 'subdomain'],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

const resources = {
  it: italianCommon
  // en: englishCommon
}

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      'it-IT': ['it'],
      it: ['it'],
      // default: ['en']
      default: ['it']
    },
    // lng: 'it', // initialize i18next with language to use
    debug: false,
    // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    detection: languageDetectorOptions, // per passare opzioni del languageDeterctor

    resources,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, (err) => {
    if (err) {
      console.error('something went wrong loading i18next, err => ', err)
    }
  })

export default i18n
