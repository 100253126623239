import React from 'react'

import logoFacebook from 'assets/img/loghi-social/facebook.svg'
import logoInstagram from 'assets/img/loghi-social/instagram.svg'
// import logoLinkedin from 'assets/img/loghi-social/linkedin.svg'
// import logoYoutube from 'assets/img/loghi-social/youtube.svg'
import { useTranslation } from 'react-i18next'
import { Container } from '@material-ui/core'

import './style.css'

// function NewsletterDialog () {
//   const [open, setOpen] = React.useState(false)
//   const { t } = useTranslation('footer')

//   const handleClickOpen = () => {
//     setOpen(true)
//   }

//   const handleClose = () => {
//     setOpen(false)
//   }

//   return (
//     <>
//     <Container>
//     <div className='newsletter'>
//     <h3>La tracciabilità è solo linizio di un percorso di trasparenza completa. Stiamo facendo in modo che tutti gli attori possano beneficiare di scambi di qualità e prezzi giusti. Iscriviti per rimanere aggiornato sugli sviluppi.</h3>
//     </div>
//     <div className="newsletter">
//         <button className='button-down' onClick={handleClickOpen}>{t('buttonText')}</button>
//     </div>

//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             <div>
//           <iframe width="540" height="500" src="https://ff2026ff.sibforms.com/serve/MUIEAJslh6ytPaN3H25OdUvVve58lgN0XAwUvxV0wccFoM1xCkooY92l0QBjaIhHbC9865z8_xfB9A05oKAJuebjAmL9AbzgrKNmnAPMOzdZ3n4a31aBYVR35Ib8kMeAIO5QQmgxYa37255_v7HrlXDObyU8R26k-5SgTsX7bcX1QYJ3APG41F8u9_RbDtdixf2n3cX15Tw4HhHj" frameBorder="0" scrolling="auto" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}></iframe>
//           </div>
//           </DialogContentText>
//         </DialogContent>
//       </Dialog>
//     {/* </div> */}
//     </Container>
//     </>
//   )
// }
const SocialBar = () => {
  const { t } = useTranslation('footer')
  return <div id="social-bar">
    <Container>
    <div className='newsletter'>
           <h3>{t('text')}</h3>
    </div>
    </Container>
    <div className='paddingIframe'>
      <iframe width="540" height="300" src="https://ff2026ff.sibforms.com/serve/MUIEALoCDqYGTkRburB-KsSW8F81alr13FsbL8D0FLy3nbNFvIWfRukonQ7T8t-NbXijVbIpUzHPzIrf9OCQ_tn1KL2F1qyXkuONA8Bm_xltTTsc6l5aUdWW9lyZNAkZghd0PuNLfXVDUHrL9LKyglBFmwRiA0AIoBbOVzKZmHQeNSQunTkmM7DGCe5TQvDBeJo8SqxNKsEbr67v" frameBorder="0" scrolling="auto" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}></iframe>
    </div>
    <a href="https://www.facebook.com/chocoplusci" target='blank'><img src={logoFacebook} alt='Facebook' /></a>
    <a href="https://www.instagram.com/chocoplusci/" target='blank'><img src={logoInstagram} alt='Instagram' /></a>

    {/* <a href="!#" target='blank'><img src={logoYoutube} alt='Youtube' /></a>
    <a href="!#" target='blank'><img src={logoLinkedin} alt='Linkedin' /></a> */}
  </div>
}

export default SocialBar
