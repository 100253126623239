import React, { createContext, useState, useEffect } from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { getData } from 'actions/AppActions'

import useLot from 'data/useLot'

import { alog } from 'utils/apioLog'
import { formatDate } from 'utils/Utils'

const LotContext = createContext()

export const LotProvider = ({ children }) => {
  const [withLot, setWithLot] = useState(false)
  const [lot, setLot] = useState([])
  const [lotNotFound, setLotNotFound] = useState(false)
  const [lotLoaded, setLotLoaded] = useState(false)

  const { t } = useTranslation('traceabilityCard')

  const lotStatic = useLot()

  useEffect(() => (lot?.traceability?.length) && setLotLoaded(true), [lot])

  const makeLot = async (gtin, lotId) => {
    try {
      alog('Data', 'fetching', 'DEBUG', 'info')
      const data = await getData(gtin, lotId)
      alog('Data', 'fetched', 'DEBUG', 'ok')

      alog('Lot', 'making', 'DEBUG', 'info')
      const mergedLot = mergeLots(data, lotStatic)
      setLot(mergedLot)
      alog('Lot', 'made', 'DEBUG', 'ok')
    } catch (err) {
      setLotNotFound(true)
      alog('Error: ', err, 'ERROR', 'error')
    }
  }

  const mergeLots = (lot, lotStatic) => {
    if (lot.traceability.length !== lotStatic.traceability.length) {
      throw new Error('Inconsistenza tra i dati statici e quelli in piattaforma')
    }

    const res = {
      ...lotStatic,
      number: lot.lotNumber,
      opentimestamps: lot.opentimestamps
    }

    // Text and shortText
    res.traceability.forEach((fase, i) => {
      fase.text = <Trans
              t={t}
              i18nKey={'cards.' + i + '.text'}
              values={{ data: formatDate(lot.traceability[i].data) }}
              components={{ 1: <em /> }}
            />

      fase.shortText = <Trans
              t={t}
              i18nKey={'cards.' + i + '.shortText'}
              values={{ data: formatDate(lot.traceability[i].data) }}
              components={{ 1: <em /> }}
            />
    })

    return res
  }

  return <LotContext.Provider value={{
    withLot,
    setWithLot,
    makeLot,
    lot,
    lotNotFound,
    lotLoaded,
    setLotNotFound
  }}>
    {children}
  </LotContext.Provider>
}

export default LotContext
