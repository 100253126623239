import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useTranslation } from 'react-i18next'

export default function DialogDiscover ({ link, lotNumber }) {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation('traceabilityCard')
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const openLink = (url) => {
    window.open(url, '_blank')
  }

  return (
    <div>
      <Button style={{ backgroundColor: '#D8EACC' }} onClick={handleClickOpen}>
      <u>{lotNumber}</u> 🔗
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="alertDetail" id="alert-dialog-title">{t('alert.title')}</DialogTitle>
        <DialogContent>
          <DialogContentText className="alertDetail" id="alert-dialog-description">
            <p>{t('alert.text')}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="alertDetail">
          <Button onClick={handleClose} >
          {t('alert.cancel')}
          </Button>
          <Button onClick={() => { openLink(link) }} autoFocus>
          {t('alert.ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
