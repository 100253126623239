require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  logTag: process.env.REACT_APP_APIO_LOG || null,
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  blockchainExplorer: 'https://www.blockchain.com',
  companyId: '48f7d940-b9d8-4638-93a0-6b15f670c6a7'
}

export default configuration
