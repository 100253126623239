import img1 from 'assets/img/partners-page/1.png'
import img2 from 'assets/img/partners-page/2.jpeg'
import img3 from 'assets/img/partners-page/3.png'
import img4 from 'assets/img/partners-page/4.png'
import img5 from 'assets/img/partners-page/5.png'

const partners = [
  {
    id: 1,
    image: img1,
    link: 'https://www.gruppoabele.org/'
  },
  {
    id: 2,
    image: img2,
    link: 'https://domori.com'
  },
  {
    id: 3,
    text: 'Partner Tecnici'
  },
  {
    id: 4,
    image: img3,
    link: 'https://www.chocofair.org/'
  },
  {
    id: 5,
    image: img4,
    link: 'https://www.apio.cc/'
  },
  {
    id: 6,
    image: img5,
    link: 'https://www.trusty.id/'
  }
]

export default partners
