import photo1 from 'assets/img/product-page/photo/1.png'
import photo2 from 'assets/img/product-page/photo/2.png'
import photo3 from 'assets/img/product-page/photo/3.png'
import photo4 from 'assets/img/product-page/photo/4.png'
import photo5 from 'assets/img/product-page/photo/5.png'
import photo6 from 'assets/img/product-page/photo/6.png'
import photo7 from 'assets/img/product-page/photo/7.png'
import photo8 from 'assets/img/product-page/photo/8.png'

const getProduct = () => {
  return {
    photo: [
      [1, photo1],
      [2, photo2],
      [3, photo3],
      [4, photo4],
      [5, photo5],
      [6, photo6],
      [7, photo7],
      [8, photo8]
    ]
  }
}

export default getProduct
