import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTitle } from '@websitehooks/use-title'

import { getProduct } from './actions/AppActions'

import useNavigation from './hooks/useNavigation'
import useLotData from './hooks/useLotData'

import MenuMobile from './components/Menu/MenuHamburger'
import NotFound from './components/NotFound/NotFound'
import LogoLoader from './components/LogoLoader/LogoLoader'
import SocialBar from './components/SocialBar/SocialBar'

import ProductPage from './views/ProductPage/ProductPage'
import CompanyPage from './views/CompanyPage/CompanyPage'
import PartnersPage from './views/PartnersPage/PartnersPage'
import TraceabilityPage from './views/TraceabilityPage/TraceabilityPage'

import { alog } from './utils/apioLog'
import configuration from './configuration'

import './assets/scss/style.scss'

const RootPage = () => {
  const [productFound, setProductFound] = useState(true)
  const [productLoaded, setProductLoaded] = useState(false)

  const { sectionActive, callback, changeSection } = useNavigation()
  const { lot, lotLoaded, makeLot } = useLotData()

  const { gtin, lotId } = useParams()
  const setTitle = useTitle('Trusty')

  // Check product's companyID
  useEffect(() => {
    alog('Product', 'checking', 'DEBUG', 'info')
    getProduct(gtin, ['companyId'])
      .then(res => {
        if (res?.companyId === configuration.companyId) {
          alog('Product', 'valid', 'DEBUG', 'ok')
          setProductLoaded(true)
        } else {
          throw new Error('Error: Ivalid gtin!')
        }
      })
      .catch((err) => {
        setProductFound(false)
        alog('Error: ', err, 'ERROR', 'error')
      })
  }, [gtin])

  useEffect(() => {
    if (productLoaded && productFound && !lotLoaded) {
      changeSection(4)
      makeLot(gtin, lotId)
    }
  }, [lotLoaded, productLoaded, productFound, changeSection, gtin, lotId, makeLot])

  useEffect(() => {
    if (lotLoaded) {
      setTitle(`Choco Plus | Lot ${lot.number}`)
    }
  }, [lotLoaded, setTitle, lot])

  useEffect(() => {
    if (callback) {
      callback.consume()
    }
  }, [sectionActive])

  const sections = {
    1: <ProductPage />,
    2: <CompanyPage />,
    3: <PartnersPage />,
    4: <TraceabilityPage />
  }

  if (!lotId) {
    return <NotFound />
  }

  if (!productFound) {
    return <NotFound product />
  }

  if (!productLoaded) {
    return <LogoLoader size='large' />
  }

  return <>
    <MenuMobile />

    {sections[sectionActive]}
    <SocialBar />
  </>
}
export default RootPage
