import React, { useState } from 'react'
import { Controller } from 'react-scrollmagic'

import { isMobileOnly } from 'react-device-detect'
import Box from '@mui/material/Box'

import useScreenOrientation from 'hooks/useScreenOrientation'
import useLotData from 'hooks/useLotData'

import { useTranslation, Trans } from 'react-i18next'

import { Container, Grid } from '@material-ui/core'

import TraceabilityCard from 'components/TraceabilityCard/TraceabilityCard'
import LogoLoader from 'components/LogoLoader/LogoLoader'
import NotFound from 'components/NotFound/NotFound'
import RotationError from 'components/RotationError/RotationError'

import headerImage from 'assets/img/traceability-page/header.png'
import blockchainImage from 'assets/img/traceability-page/blockchain.svg'
import lastPageHeader from 'assets/img/traceability-page/last-page/header.png'
import lastLogo1 from 'assets/img/traceability-page/last-page/1.svg'
import lastLogo2 from 'assets/img/traceability-page/last-page/2.svg'
import lastLogo3 from 'assets/img/traceability-page/last-page/3.svg'
import lastLogo4 from 'assets/img/traceability-page/last-page/4.svg'

import BlockchainModal from './BlockchainModal'

import prodottoImage from 'assets/img/traceability-page/prodotto.png'

const TraceabilityPage = () => {
  const { lotNotFound, lotLoaded, lot } = useLotData()
  const { isLandscape } = useScreenOrientation()
  const { t } = useTranslation('traceabilityPage')

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleClick = () => {
    setIsOpenModal(true)
  }

  if (lotNotFound) {
    return <NotFound lot />
  }
  if (!lotLoaded) {
    return <LogoLoader size='large' />
  }
  if (isMobileOnly && isLandscape) {
    return <RotationError />
  }

  return (<>
    <BlockchainModal
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
    />

    <div id="traceability-page">
      <section id="header">
        <div id="logo-circle">
          <div id="circle">
            <img src={prodottoImage} alt="" />
          </div>
        </div>
      </section>
      <section className="rounded-page">
        <section id="first-page">
        <Container>
          <h2 className='primary-color'>{t('firstPage.title')}</h2>
          <div className='center-text'>
            <img src={headerImage} alt='' />
          </div>
          <Box sx={{
            width: 'auto',
            height: 'auto',
            padding: ' 5px',
            backgroundColor: '#87837826',
            '&:hover': {
              backgroundColor: '#87837826',
              opacity: [0.9, 0.8, 0.7]
            }
          }}>
            <p>{t('firstPage.hint')}</p>
              </Box>
              <p id="callout"> {t('firstPage.text')}</p>
        </Container>
      </section>
      <section id="cards">
        <Controller>
          <>
            {lot.traceability.map(fase => <TraceabilityCard key={fase.id} card={fase} />)}
          </>
        </Controller>
      </section>
      <section id="blockchain">
        <Container>
          <Grid container spacing={3} alignItems='center'>
            <Grid item xs={3}>
              <img src={blockchainImage} alt='Blockchain' />
            </Grid>
            <Grid item xs={9}>
              <p>
                <Trans
                  t={t}
                  i18nKey='blockchain.text'
                  components={{ 1: <span /> }}
                />
              </p>
            </Grid>
          </Grid>
          <div className='verifica'>
            <button className='custom-button' onClick={handleClick}>{t('blockchain.button')}</button>
          </div>
        </Container>
      </section>
      <section id="last-page">
        <img src={lastPageHeader} alt='' />
        <Container>
          <h3>{t('lastPage.text')}</h3>
          <ul>
            <li>
              <img src={lastLogo1} alt='' />
              <div>{t('lastPage.list.1')}</div>
            </li>
            <li>
              <Container>
                <img src={lastLogo2} alt='' />
                <div>{t('lastPage.list.2')}</div>
              </Container>
            </li>
            <li>
              <img src={lastLogo3} alt='' />
              <div>{t('lastPage.list.3')}</div>
            </li>
            <li>
              <img src={lastLogo4} alt='' />
              <div>{t('lastPage.list.4')}</div>
            </li>
          </ul>
        </Container>
      </section>
      </section>
    </div>
  </>)
}

export default TraceabilityPage
