import React from 'react'

// import { useTranslation } from 'react-i18next'

import { Container } from '@material-ui/core'

const TraceabilityTable = ({ data }) => {
  // const { t } = useTranslation('traceabilityCard')

  return data.tabs.map(x => {
    return (<div key={x.key} className={x.className ? x.className : 'tabella-tracciabilita'}>
      <Container>
        <p>{x.title}</p>
        <table>
          <tbody>
            {x.rows.map((row, index) => {
              return (<tr key={`${x.key}_row_${index}`}>
                {row.map((x, ind) => {
                  return <td key={`${x.key}_row_${index}_col_${ind}`}>{x}</td>
                })}
              </tr>)
            })}
          </tbody>
        </table>
      </Container>

    </div>)
  })
}

export default TraceabilityTable
